import {createStore, applyMiddleware, compose} from "redux";
// noinspection ES6CheckImport
import createSagaMiddleware from 'redux-saga';
import rootReducer from "../reducers/index";
import rootSaga from "../sagas";

//noinspection JSUnresolvedVariable
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;